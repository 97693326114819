/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EDetailAvalable } from './EDetailAvalable';
import {
    EDetailAvalableFromJSON,
    EDetailAvalableFromJSONTyped,
    EDetailAvalableToJSON,
} from './EDetailAvalable';

/**
 * Rozšířené informace o projektu
 * @export
 * @interface ProjectExtendedBaseResultDataType
 */
export interface ProjectExtendedBaseResultDataType {
    /**
     * 
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    resultCode?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectExtendedBaseResultDataType
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    errorMessage?: string | null;
    /**
     * Id projektu
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    id?: number;
    /**
     * Projektový kód) (unikátní identifikátor)
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly code?: string | null;
    /**
     * Název projektu
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    name?: string | null;
    /**
     * Stav projektu
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    status?: string | null;
    /**
     * Trvání projektu (v letech)
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    period?: number;
    /**
     * Cílová částka
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    targetAmount?: number;
    /**
     * Aktuální částka (pozor na případné cache) - přislíbená částka
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     * @deprecated
     */
    currentAmount?: number;
    /**
     * Očekávaná částka
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    expectedAmount?: number;
    /**
     * Měna
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    currencyCode?: string | null;
    /**
     * Adresa obrázku
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    imageLink?: string | null;
    /**
     * Roční výnos % (Dividenda / Period)
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly avgYearPrc?: number;
    /**
     * Odhad dividenda %
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    dividendPrc?: number;
    /**
     * Odhad exit %
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    exitPrc?: number;
    /**
     * Text počtu dní do konce investování (automaticky formátovaná doba ke konci akt. inv. kampaně k zobrazení uživateli)
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    investRemainingTime?: string | null;
    /**
     * Konec poslední aktivní investiční kampaně (Detail kampaně )
     * (null = není aktivní, nebo žádná |
     * budoucí dt mělo by jít investovat [s ohledem na další podmínky] |
     * minulý dt kampaň již proběhla)
     * @type {Date}
     * @memberof ProjectExtendedBaseResultDataType
     */
    lastInvestCampaignEnd?: Date | null;
    /**
     * Název skupiny zobrazení
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    statusClass?: string | null;
    /**
     * Ikona stavu
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    statusIcon?: string | null;
    /**
     * Min. úroveň uživatele pro zobrazení detailu
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    minMembershipLevel?: number;
    /**
     * Id stavu projektu
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly statusId?: number;
    /**
     * Stav kód
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly statusCode?: string | null;
    /**
     * Aktuálně přislíbená částka
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly promisedAmount?: number;
    /**
     * Aktuálně započtená částka
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly countedAmount?: number;
    /**
     * Poslední hodnota dividendy % s ohledem na KPI
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly lastDividendPrc?: number;
    /**
     * Poslední hodnota exitu % s ohledem na KPI
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly lastExitPrc?: number;
    /**
     * Poslední hodnota datumu exitu s ohledem na KPI
     * @type {Date}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly lastExitDate?: Date;
    /**
     * Aktuální kurz vůči CZK
     * @type {number}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly czkExchangeRate?: number;
    /**
     * Přednostní vyplacení jistiny
     * @type {boolean}
     * @memberof ProjectExtendedBaseResultDataType
     */
    principalFirst?: boolean;
    /**
     * Perex
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    readonly perex?: string | null;
    /**
     * Popis projektu
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    description?: string | null;
    /**
     * Odkaz na projekt
     * @type {string}
     * @memberof ProjectExtendedBaseResultDataType
     */
    projectLink?: string | null;
    /**
     * 
     * @type {EDetailAvalable}
     * @memberof ProjectExtendedBaseResultDataType
     */
    fullDetailAvailability?: EDetailAvalable;
}



/**
 * Check if a given object implements the ProjectExtendedBaseResultDataType interface.
 */
export function instanceOfProjectExtendedBaseResultDataType(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectExtendedBaseResultDataTypeFromJSON(json: any): ProjectExtendedBaseResultDataType {
    return ProjectExtendedBaseResultDataTypeFromJSONTyped(json, false);
}

export function ProjectExtendedBaseResultDataTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectExtendedBaseResultDataType {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'resultCode': !exists(json, 'resultCode') ? undefined : json['resultCode'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'errorMessage': !exists(json, 'errorMessage') ? undefined : json['errorMessage'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'targetAmount': !exists(json, 'targetAmount') ? undefined : json['targetAmount'],
        'currentAmount': !exists(json, 'currentAmount') ? undefined : json['currentAmount'],
        'expectedAmount': !exists(json, 'expectedAmount') ? undefined : json['expectedAmount'],
        'currencyCode': !exists(json, 'currencyCode') ? undefined : json['currencyCode'],
        'imageLink': !exists(json, 'imageLink') ? undefined : json['imageLink'],
        'avgYearPrc': !exists(json, 'avgYearPrc') ? undefined : json['avgYearPrc'],
        'dividendPrc': !exists(json, 'dividendPrc') ? undefined : json['dividendPrc'],
        'exitPrc': !exists(json, 'exitPrc') ? undefined : json['exitPrc'],
        'investRemainingTime': !exists(json, 'investRemainingTime') ? undefined : json['investRemainingTime'],
        'lastInvestCampaignEnd': !exists(json, 'lastInvestCampaignEnd') ? undefined : (json['lastInvestCampaignEnd'] === null ? null : new Date(json['lastInvestCampaignEnd'])),
        'statusClass': !exists(json, 'statusClass') ? undefined : json['statusClass'],
        'statusIcon': !exists(json, 'statusIcon') ? undefined : json['statusIcon'],
        'minMembershipLevel': !exists(json, 'minMembershipLevel') ? undefined : json['minMembershipLevel'],
        'statusId': !exists(json, 'statusId') ? undefined : json['statusId'],
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'promisedAmount': !exists(json, 'promisedAmount') ? undefined : json['promisedAmount'],
        'countedAmount': !exists(json, 'countedAmount') ? undefined : json['countedAmount'],
        'lastDividendPrc': !exists(json, 'lastDividendPrc') ? undefined : json['lastDividendPrc'],
        'lastExitPrc': !exists(json, 'lastExitPrc') ? undefined : json['lastExitPrc'],
        'lastExitDate': !exists(json, 'lastExitDate') ? undefined : (new Date(json['lastExitDate'])),
        'czkExchangeRate': !exists(json, 'czkExchangeRate') ? undefined : json['czkExchangeRate'],
        'principalFirst': !exists(json, 'principalFirst') ? undefined : json['principalFirst'],
        'perex': !exists(json, 'perex') ? undefined : json['perex'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'projectLink': !exists(json, 'projectLink') ? undefined : json['projectLink'],
        'fullDetailAvailability': !exists(json, 'fullDetailAvailability') ? undefined : EDetailAvalableFromJSON(json['fullDetailAvailability']),
    };
}

export function ProjectExtendedBaseResultDataTypeToJSON(value?: ProjectExtendedBaseResultDataType | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'resultCode': value.resultCode,
        'error': value.error,
        'errorMessage': value.errorMessage,
        'id': value.id,
        'name': value.name,
        'status': value.status,
        'period': value.period,
        'targetAmount': value.targetAmount,
        'currentAmount': value.currentAmount,
        'expectedAmount': value.expectedAmount,
        'currencyCode': value.currencyCode,
        'imageLink': value.imageLink,
        'dividendPrc': value.dividendPrc,
        'exitPrc': value.exitPrc,
        'investRemainingTime': value.investRemainingTime,
        'lastInvestCampaignEnd': value.lastInvestCampaignEnd === undefined ? undefined : (value.lastInvestCampaignEnd === null ? null : value.lastInvestCampaignEnd.toISOString()),
        'statusClass': value.statusClass,
        'statusIcon': value.statusIcon,
        'minMembershipLevel': value.minMembershipLevel,
        'principalFirst': value.principalFirst,
        'description': value.description,
        'projectLink': value.projectLink,
        'fullDetailAvailability': EDetailAvalableToJSON(value.fullDetailAvailability),
    };
}

