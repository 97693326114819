/* tslint:disable */
/* eslint-disable */
/**
 * VentureClub.RestApi2
 * 1.33.2.3, env PROD
 *
 * The version of the OpenAPI document: 1.0
 * Contact: it@spinware.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InvoiceContact } from './InvoiceContact';
import {
    InvoiceContactFromJSON,
    InvoiceContactFromJSONTyped,
    InvoiceContactToJSON,
} from './InvoiceContact';
import type { InvoiceItem } from './InvoiceItem';
import {
    InvoiceItemFromJSON,
    InvoiceItemFromJSONTyped,
    InvoiceItemToJSON,
} from './InvoiceItem';
import type { MENA } from './MENA';
import {
    MENAFromJSON,
    MENAFromJSONTyped,
    MENAToJSON,
} from './MENA';
import type { PaymentMethods } from './PaymentMethods';
import {
    PaymentMethodsFromJSON,
    PaymentMethodsFromJSONTyped,
    PaymentMethodsToJSON,
} from './PaymentMethods';

/**
 * Faktura
 * @export
 * @interface InvoiceData
 */
export interface InvoiceData {
    /**
     * Id společnosti vydávající fakturu<br />
     * VC = 1<br />
     * VCI = 2
     * @type {string}
     * @memberof InvoiceData
     */
    issuerCode?: string | null;
    /**
     * Číselná řada iDokladu
     * @type {number}
     * @memberof InvoiceData
     */
    numberSeriesNoId?: number | null;
    /**
     * Identifikace z externího systému, např. platebního terminálu
     * @type {string}
     * @memberof InvoiceData
     */
    externalIdentificator?: string | null;
    /**
     * 
     * @type {MENA}
     * @memberof InvoiceData
     */
    currency?: MENA;
    /**
     * 
     * @type {PaymentMethods}
     * @memberof InvoiceData
     */
    paymentMethod?: PaymentMethods;
    /**
     * Faktura je určena pro právnickou osobu, nutné v případě uložené registrace
     * @type {boolean}
     * @memberof InvoiceData
     */
    forCompany?: boolean;
    /**
     * Číslo identifikace existující registrace, kontaktní údaje budou použity z ní. Customer není použit,<br />
     * Pozn. Identification nemusí u registrace existovat, pak je nutné použít RegistrationId
     * @type {string}
     * @memberof InvoiceData
     */
    identification?: string | null;
    /**
     * Id existující registrace, kontaktní údaje budou použity z ní. Customer není použit
     * @type {number}
     * @memberof InvoiceData
     */
    registrationId?: number | null;
    /**
     * 
     * @type {InvoiceContact}
     * @memberof InvoiceData
     */
    customer?: InvoiceContact;
    /**
     * Interní popis faktury
     * @type {string}
     * @memberof InvoiceData
     */
    description?: string | null;
    /**
     * Text před položkami faktury
     * @type {string}
     * @memberof InvoiceData
     */
    itemsTextPreffix?: string | null;
    /**
     * Text po položkách faktury
     * @type {string}
     * @memberof InvoiceData
     */
    itemsTextSuffix?: string | null;
    /**
     * Položky faktury
     * @type {Array<InvoiceItem>}
     * @memberof InvoiceData
     */
    items?: Array<InvoiceItem> | null;
    /**
     * Odeslat fakturu zákaznikovi na email
     * @type {boolean}
     * @memberof InvoiceData
     */
    sendToCustomer?: boolean;
}



/**
 * Check if a given object implements the InvoiceData interface.
 */
export function instanceOfInvoiceData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceDataFromJSON(json: any): InvoiceData {
    return InvoiceDataFromJSONTyped(json, false);
}

export function InvoiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'issuerCode': !exists(json, 'issuerCode') ? undefined : json['issuerCode'],
        'numberSeriesNoId': !exists(json, 'numberSeriesNoId') ? undefined : json['numberSeriesNoId'],
        'externalIdentificator': !exists(json, 'externalIdentificator') ? undefined : json['externalIdentificator'],
        'currency': !exists(json, 'currency') ? undefined : MENAFromJSON(json['currency']),
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : PaymentMethodsFromJSON(json['paymentMethod']),
        'forCompany': !exists(json, 'forCompany') ? undefined : json['forCompany'],
        'identification': !exists(json, 'identification') ? undefined : json['identification'],
        'registrationId': !exists(json, 'registrationId') ? undefined : json['registrationId'],
        'customer': !exists(json, 'customer') ? undefined : InvoiceContactFromJSON(json['customer']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'itemsTextPreffix': !exists(json, 'itemsTextPreffix') ? undefined : json['itemsTextPreffix'],
        'itemsTextSuffix': !exists(json, 'itemsTextSuffix') ? undefined : json['itemsTextSuffix'],
        'items': !exists(json, 'items') ? undefined : (json['items'] === null ? null : (json['items'] as Array<any>).map(InvoiceItemFromJSON)),
        'sendToCustomer': !exists(json, 'sendToCustomer') ? undefined : json['sendToCustomer'],
    };
}

export function InvoiceDataToJSON(value?: InvoiceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'issuerCode': value.issuerCode,
        'numberSeriesNoId': value.numberSeriesNoId,
        'externalIdentificator': value.externalIdentificator,
        'currency': MENAToJSON(value.currency),
        'paymentMethod': PaymentMethodsToJSON(value.paymentMethod),
        'forCompany': value.forCompany,
        'identification': value.identification,
        'registrationId': value.registrationId,
        'customer': InvoiceContactToJSON(value.customer),
        'description': value.description,
        'itemsTextPreffix': value.itemsTextPreffix,
        'itemsTextSuffix': value.itemsTextSuffix,
        'items': value.items === undefined ? undefined : (value.items === null ? null : (value.items as Array<any>).map(InvoiceItemToJSON)),
        'sendToCustomer': value.sendToCustomer,
    };
}

